nav{
    background: rgba(0, 0, 0, 0.3);
    width: max-content;
    display: block;
    padding: 0.7rem 1.7rem;
    z-index: 999;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 2rem;
    display: flex;
    gap: 0.8rem;
    border-radius: 3rem;
    backdrop-filter: blur(15px);
}

nav a{
    background-color: transparent;
    padding: 0.9rem;
    border-radius: 50%;
    display: flex;
    color: white;
    font-size: 1.5rem;
}

nav a:hover{
    background: rgba(0, 0, 0, 0.4);
}

nav a.active{
    background: rgba(109, 109, 109, 0.233)
    
}

