.gb {
  padding: 8px 20px;
  margin-right: 5px;
  margin-left: 5px;
  cursor: pointer;
  border-radius: 8px;
  font-size: 0.8rem;
  line-height: 1.8rem;
  text-transform: none;
  font-weight: bold;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
}

.gb-bordered {
  padding: 6px 20px;
  position: relative;
  border: 3px solid transparent;
  background-clip: padding-box;
  transition: 0.5s all;
}
.gb-bordered:after {
  transition: 0.5s all;
  position: absolute;
  top: -3px;
  left: -3px;
  right: -3px;
  bottom: -3px;
  content: "";
  z-index: -1;
  border-radius: 8px;
}

.gb-rounded {
  border-radius: 50px;
}
.gb-rounded.gb-bordered:after {
  border-radius: 50px;
}

.hover-fill {
  transition: 0.6s all;
  background-color: #2d2d2e;
  color: white;
}
.hover-fill:hover {
  background-color: transparent;
  color: white;
}
.hover-fill.reverse {
  background-color: transparent;
  color: white;
}
.hover-fill.reverse:hover {
  background-color: #2d2d2e;
}

.light .hover-fill {
  transition: 0.6s all;
  background-color: #3f3f40;
  color: white;
}
.light .hover-fill:hover {
  background-color: transparent;
  color: white;
}
.light .hover-fill.reverse {
  background-color: transparent;
  color: white;
}
.light .hover-fill.reverse:hover {
  background-color: #3f3f40;
}

.hover-slide {
  transition: 0.6s all;
}
.hover-slide:after {
  background-size: 200% 100%;
  background-position: 0 0;
}
.hover-slide:hover:after {
  background-position: 100% 0;
}

#gb1:after {
  background-image: linear-gradient(135deg, #32a252 0%, #32a252 22%, #2a8a45 22%, #2a8a45 28%, #228131 28%, #228131 72%, #3f8e48 72%, #3f8e48 100%);
}

