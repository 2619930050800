.gb {
  padding: 8px 20px;
  margin-right: 5px;
  margin-left: 5px;
  cursor: pointer;
  border-radius: 8px;
  font-size: 0.8rem;
  line-height: 1.8rem;
  text-transform: none;
  font-weight: bold;
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
}

$border-width: 3px;

.gb-bordered {
  padding: 6px 20px;
  position: relative;
  border: $border-width solid transparent;

  background-clip: padding-box;
  transition: .5s all;

  &:after {
    transition: .5s all;
    position: absolute;
    top: -$border-width;
    left: -$border-width;
    right: -$border-width;
    bottom: -$border-width;
    content: '';
    z-index: -1;
    border-radius: 8px;
  }
}

.gb-rounded {
  border-radius: 50px;
  &.gb-bordered:after {
    border-radius: 50px;
  }
}

.hover-fill {
  transition: .6s all;
  background-color: #1d1d1e;
  color: white;
  &:hover {
    background-color: transparent;
    color: white;
  }
  &.reverse {
    background-color: transparent;
    color: white;
    &:hover {
      background-color: #1d1d1e;
    }
  }
}
.light .hover-fill {
  transition: .6s all;
  background-color: #3f3f40;
  color: white;
  &:hover {
    background-color: transparent;
    color: white;
  }
  &.reverse {
    background-color: transparent;
    color: white;
    &:hover {
      background-color: #3f3f40;
    }
  }
}


.hover-slide {
  transition: .6s all;

  &:after {
    background-size: 200% 100%;
    background-position: 0 0;
  }
  &:hover {
    &:after {
      background-position: 100% 0;
    }
  }
}




#gb1 {
  &:after {
    background-image: linear-gradient(135deg, #32A252FF 0%, #32A252FF 22%, #2A8A45FF 22%, #2A8A45FF 28%, #228131FF 28%, #228131FF 72%, #3F8E48FF 72%, #3F8E48FF 100%);
  }
}

