#blogPage{
    margin-top: 1rem;
}

.titleHeader{
    color: white;
    background-color: var(--color-accent-green1-op);
    border-radius: 1rem;
    font-size: 1.8rem;
    margin-left: -50rem;
    padding-left: 45rem;
    display: flex;
    justify-content: space-between;
}
.titleHeader .title_container{
    order: 1;
    display: flex;
}
.titleHeader h3{
    text-align: left;
    padding-left: 0.5rem;
}
.blogPost{
    font-weight: normal;
    color: white;
}
.blogPost p{
    font-weight: lighter;
    padding-bottom: 0.5rem;
}

.blogPost h3{
    text-align: left;
}
.blogPost .titleAndDate{
    display: flex;
    justify-content: space-between;
    color: white;
    padding-top: 0.5rem;
    text-align: left;
}

.blogPost .titleAndDate h4,.blogPost .titleAndDate h5{
    font-size: 1.8rem;
    text-align: left;
}
.blogPost .titleAndDate h5{
    margin-left: 1.5rem;
    color: var(--color-accent-green1);
}
.alinea{
    display: flex;
    padding-bottom: 2rem;
}
.alinea .blogText{
    width: 55%;
    overflow: hidden;
}

.alinea .blogPicture{
    width: 45%;
}

.titleHeader .backIcon{
    padding-top: 0.25rem;
    cursor: pointer;
}
.titleHeader .backIcon .backArrow1,.titleHeader .backIcon .backArrow2{
    opacity: 0;
}
.titleHeader .backIcon .backArrow1{
    transition: all 300ms ease-in-out;
}
.titleHeader .backIcon .backArrow2{
    transition: all 300ms ease-in-out;
}

.titleHeader .backIcon:hover .backArrow1,.titleHeader .backIcon:hover .backArrow2{
    opacity: 1;
}

.titleHeader .backIcon:hover .backArrow2{
    transition-delay: 0.2s;
}

#blogPage .titleHeader .language_container{
    position: static;
    float: end;
    order: 2;
    font-size: 1rem;
}
#blogPage .titleHeader .language_container div{
    margin-top: -2rem;
    padding: 1rem 0.7rem 0.7rem;
    background-color: var(--color-gray);
    border-color: var(--color-gray);
    transform: translateY(83%);
}
#blogPage .titleHeader .language_container div:hover{
    background-color: transparent;
    border-color: white;
}

#blogPage .language_container div .flag {
    height: 1rem;
    width: 1.4rem;
}
.blogPost .category{
    display: flex;
    padding-left: 0.5rem;
}
.blogPost .category .cube{
    height: 0.9rem;
    width: 1.5rem;
    border-bottom: 1px solid white;
    border-left: 1px solid white;
    border-bottom-left-radius: 8px;
    margin-right: 0.25rem;
}
.blogPost .category .text{
    font-weight: lighter;
    font-size: 1rem;
}
/* Media queries(small devices) */
@media screen and (max-width:1150px){
    .titleHeader {
        padding-left: 48rem;
    }
}

@media screen and (max-width:600px){
    .titleHeader {
        padding-left: 49rem;
    }
    #blogPage .titleHeader .language_container{
        position: static;
        float: end;
        order: 2;
        transform: translateY(57%);
    }
}
@media screen and (max-width:1024px){
    .alinea{
        display: flex;
        flex-direction: column;
    }

    .blogPost .alinea:nth-child(even){
        flex-direction: column-reverse;
    }
    .alinea .blogText{
        width: 100%;
    }

    .alinea .blogPicture{
        width: 100%;
    }

    .alinea .blogPicture .carousel{
        width: 100%;
    }

    .blogPost .titleAndDate h4,.blogPost .titleAndDate h5{
        font-size: 1.4rem;
    }

}
