footer{
    background: var(--color-accent-green1-op);
    padding: 3rem 0;
    text-align: center;
    margin-top: 7rem;
}

footer>a{
    font-size: 2rem;
    font-weight: bold;
}

.footer_logo{
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 2rem;
    display: inline-block;

}

.permalinks{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin: 0 auto 1.5rem;

}

.footer_socials{
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 5rem;
}

.footer_socials a{
    background: var(--color-gray);
    padding: 0.8rem;
    border-radius: 0.7rem;
    display: flex;
}
.footer_socials a:hover *{
    transition: var(--transition2);
}
.footer_socials a:hover *{
    color: var(--color-accent-green1);
}

.footer_socials a.footerButtonClicked{
    transform: scale(150%) rotate(45deg);
}
.footer_socials a.footerButtonClicked *{
    color: var(--color-accent-green2);
}

footer > .permalinks > li > a {
    position: relative;
}

footer > .permalinks > li > a::after {
    content: "";
    position: absolute;
    bottom: -20%;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 3px;
    background-color: var(--color-text1);
    transition: width 0.3s ease-in-out;
}

footer > .permalinks > li > a:hover::after {
    width: 100%;
}
@media screen and (max-width:600px){
    .permalinks{
        flex-direction: column;
        gap: 1.5rem;
    }
}