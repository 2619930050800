.about_container{
    display: grid;
    grid-template-columns: 35% 50%;
    gap: 15%;
}

.about_me{
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    background: linear-gradient(
        45deg, transparent, var(--color-accent-green1), transparent
    );
    display: grid;
    place-items: center;
}

.about_me-image{
    border-radius: 2rem;
    overflow: hidden;
    transform: rotate(10deg);
    transition: var(--transition1);
}

.about_me-image:hover{
    transform: rotate(0deg);
}

.about_cards{
    display: flex;
    grid-template-columns: repeat(3,1fr);
    gap: 1.5rem;
}

.about_card{
    background: var(--color-accent-green1-op);
    border: 1px solid transparent;
    border-radius: 1rem;
    padding: 2rem;
    text-align: center;
    transition: var(--transition1);
    overflow: hidden;
}

.about_card:hover{
    background: var(--color-accent-green2-op);
    cursor: default;
}

.about_icon{
    color: white;
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.about_content p{
    margin: 0.6rem 0 0.6rem;
    color: white;
    font-weight: lighter;

}

#contact .about_card{
    width: 33.33%;
    overflow: hidden;
}

#contact .about_card p{
    color: #a4a4a4;
    font-size: 0.9rem;
    margin: 0 0 0.75rem;
}

#contact .about_card p.email{
    margin: 0 0 0;
}

/* Media queries(medium devices) */
@media screen and (max-width:1024px){
    .about_container{
        grid-template-columns: 1fr;
        gap:0;
    }

    .about_me{
        width: 50%;
        margin: 2rem auto 4rem;
    }

    .about_content p{
        margin: 1rem 0 1.5rem;
    }

    .about_card{
        width: 50%;
    }

}


/* Media queries(medium small) */
@media screen and (max-width:600px){
    .about_me{
        width: 65%;
        margin: 0 auto 3rem;
    }

    .about_cards{
        gap:1rem;
        flex-direction: column;
    }
    .about_card{
        width: 100%;
    }
    .about_content{
        text-align: center;
    }

    .about_content p{
        margin: 1.5rem 0;
    }
    #contact .about_card{
        width: 100%;
        overflow: hidden;
    }
}