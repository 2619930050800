header{
    height: 100vh;
    padding-top: 3rem;
    overflow: hidden;
}

.header_container {
    text-align: center;
    height: 100%;
    position: relative;
}






/*Buttons*/
.buttons{
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}
.buttons .btn-primary:hover {
    color: white;
}






.header_socials{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}
.header_socials>a>*{
    size: 5rem;
}

.header_socials::after{
    content: "";
    width: 1px;
    height: 2rem;
    background: var(--color-accent-green1);
}






.me-image{
    display: flex;
    width: 25rem;
    height: 25rem;
    position: relative;
    left: calc(50% - 12.5rem);
    border-radius: 1rem;
    overflow: hidden;
}

.me-image img{
    margin-right: auto;
    margin-left: auto;
    width: 25rem;
    z-index: 999;
    overflow: hidden;
}

canvas {
    position: absolute;
    background-color: rgba(255, 255, 255, 0);
    z-index: 998;
    width: 25rem;
    height: 25rem;
    opacity: 95%;
}

.me{
    width: 25rem;
    background: linear-gradient(
            45deg, transparent, var(--color-accent-green1), transparent
    );
    display: grid;
    place-items: center;
    height: 25rem;
    margin-top: 4rem;
    margin-left: auto;
    margin-right: auto;
    border-radius: 1rem;
}

.me-image{
    overflow: hidden;
    transform: rotate(10deg);
    transition: var(--transition1);
}

.me-image:hover{
    transform: rotate(0deg);
}






.scroll_down{
    position: absolute;
    right: -2.5rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}
.header_container a:hover {
    color: var(--color-accent-green1);
}



.language_container{
    line-height: 0.5;
    right: 0;
    position: fixed;
    z-index: 999;
}

.language_container div{
    display: flex;
    justify-content: flex-end;
    float: right;
    padding-top: 1.25rem;
    margin-right: 2rem;
    width: fit-content;
    -webkit-column-width: min-content;
    z-index: 999;
    margin-top: -2rem;
}

.language_container div .flag{
    height: 1.4rem;
    width: 1.8rem;
    transform: translateY(-25%);
    padding-right: 0.25rem;
    -webkit-padding-before: 0.25rem;
    object-fit: scale-down;
}


.lightModeIcon{
    transition: var(--transition1);
}
.lightModeIcon:hover{
    cursor: pointer;
    color: var(--color-accent-green1);
}


/* Media queries(medium small) */
@media screen and (max-width:1800px){
    .header_socials{
        padding-bottom: 9rem;
    }
    .scroll_down{
        bottom: 14rem;
    }
    .header_container .me {
        width: 15rem;
        height: 15rem;
    }
    .header_container canvas {
        width: 15rem;
        height: 15rem;
    }

    .header_container .me-image {
        width: 15rem;
        height: 15rem;
        left: calc(50% - 7.5rem);
    }
    .header_container img {
        width: 15rem;
        height: 15rem;
    }

    .buttons a{
        font-size: 0.8rem;
    }
}

/* Media queries(medium devices) */
@media screen and (max-width:1024px){
    header{
        padding-top: 4rem;
    }

    header h1{
        padding-bottom: 0.3rem;
    }

    header .buttons{
        margin-top: 1.25rem;
    }
    header .language_container{
        line-height: 0.3;
    }

    header .language_container div{
        font-size: 0.8rem;
        padding: 0.8rem 0.6rem 0.4rem;
        margin-top: -3rem;
    }

    header .language_container div .flag{
        height: 0.8rem;
        width: 1.1rem;
        transform: translateY(-25%);
        padding-right: 0.25rem;
        -webkit-padding-before: 0.25rem;
        object-fit: scale-down;

    }

    header .language_container .lightModeIcon{
        padding-right: 8rem;
    }
}

@media screen and (max-width:450px){
    .header_socials{
        padding-bottom: 10rem;
    }
    .scroll_down{
        bottom: 15rem;
    }
    .header_container .me {
        width: 12rem;
        height: 12rem;
    }
    .header_container canvas {
        width: 12rem;
        height: 12rem;
    }

    .header_container .me-image {
        width: 12rem;
        height: 12rem;
        left: calc(50% - 6rem);
    }
    .header_container img {
        width: 12rem;
        height: 12rem;
    }

}