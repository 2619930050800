@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;600&display=swap');


* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

:root {
    --color-background-gray1: #1a1a1a;
    --color-background-gray2: #323232;
    --color-accent-green1: rgb(43, 140, 61);
    --color-accent-green1-op: rgba(43, 140, 61, 0.5);
    --color-accent-green2: rgb(51, 180, 77);
    --color-accent-green2-op: rgba(51, 180, 77, 0.6);
    --color-accent-green3: rgb(5, 100, 34);
    --color-gray: rgb(51, 51, 51);
    --color-gray-op: rgba(37, 37, 37, 0.6);
    --color-text1: #fff;
    --color-text2: #b3b3b3;

    --color-gray-text:rgb(189, 189, 189);
    --transition1: all 400ms ease;
    --transition2: all 200ms ease;
    --container-width-big: 85%;
    --container-width-medium: 88%;
    --container-width-small:93%;

}

html{
    scroll-behavior: smooth;
}

::-webkit-scrollbar{
    display: none;
}

body{
    font-family: 'Poppins', sans-serif;
    background: var(--color-background-gray1);
    color: #fff;
    line-height: 1.7;
}

.background{
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background: url(../src/assets/background.jpg) no-repeat center;
    background-size: cover;
    position: fixed;
    height: 100vh;
    width: 100vw;
    z-index: -5;
    transition: var(--transition1);
    filter: brightness(175%);
}
.background.light{
    background: url(../src/assets/background.jpg) no-repeat center;
    filter: brightness(265%);

    background-size: cover;
    position: fixed;
    height: 100vh;
    width: 100vw;
}
.content{
    position: absolute;
    width: 100vw;
}

/* General Styles */
.container {
    width: var(--container-width-big);
    margin: 0 auto;
}

section {
    width: var(--container-width-big);
    margin: 0 auto;
}

h1, h2, h3, h4, h5 {
    text-align: center;
    font-weight: 500; 
}

h1{
    font-weight: bold;
    font-size: 2.5rem;
    color: var(--color-accent-green1);
    padding-bottom: 0.75rem;
}

h5{
    color: white
}

small{
    color: rgb(189, 189, 189)
}

section {
    margin-top: 8rem;
}

section > h2, section > h5 {
    text-align: center;
}

section > h2 {
    color: var(--color-text1);
    margin-bottom: 3rem;
}

section > h5 {
    color: white;
}

.text-light {
    color: var(--color-text2);
}

a {
    color: var(--color-text1);
    transition: var(--transition1);
}


.btn {
    width: max-content;
    display: inline-block;
    color: var(--color-accent-green1);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-accent-green1);
    transition: var(--transition1);
}

.btn:hover {
    background: var(--color-text1);
    color: var(--color-background-gray2);
    border-color: transparent;
}

.btn-primary {
    width: max-content;
    display: inline-block;
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-accent-green1);
    transition: var(--transition1);
    background: var(--color-accent-green1);
    color: white;
}

.btn-primary:hover {
    border-color: white;
    color: white;
    background-color: transparent;
}

img {
    display: block;
    width: 100%;
    object-fit: cover;
}

/* Media queries(medium devices) */
@media screen and (max-width:1024px){
    .container{
        width: var(--container-width-medium);
    }

    section{
        margin-top: 6rem;
    }
}


/* Media queries(small devices) */
@media screen and (max-width:600px){
    .container{
        width: var(--container-width-small);
    }

    section > h2{
        margin-top: 2rem;
    }
}