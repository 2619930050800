.carousel{
    display: flex;
    width: 85%;
    height: 25rem;
    margin-right: auto;
    margin-left: auto;
}

.carouselWrapper{
    position: relative;
    height: 100%;
    width: 100%;
}

.carouselCard{
    display: flex;
    flex: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 0.5rem;
    -webkit-box-shadow: 0px 0px 11px 3px rgba(0, 0, 0, 0.35);
    box-shadow: 0px 0px 11px 3px rgba(0, 0, 0, 0.15);
    opacity: 0;

    transform: scale(0.8);
    transition: var(--transition1);

}
.carouselCardActive{
    opacity: 1;
    transform: scale(1);
}
.cardImage{
    width: 100%;
    object-fit: cover;
}

.carouselCardOverlay{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 998;
    pointer-events: none;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.70));
}

.carouselCardOverlayText{
    position: absolute;
    bottom: 1.75rem;
    left: 1rem;
}

.carouselCardTitle{
    font-size: 2rem;
    z-index: 997;
}
.carouselCard{
    visibility: hidden;
    z-index: 996;
    transition: var(--transition1);
}

.carouselCardActive{
    visibility: visible;
    transition: var(--transition1);
}

.carouselCardClick{
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
    font-size: 1rem;
    font-weight: lighter;
}

.carouselCardClickVisable,.carouselWrapper:hover .carouselCardClick{
    opacity: 1;
}

.carouselCardClickHidden{
    opacity: 0;
}

.carouselCardActive>img,.carouselCardActive>video{
    display: initial;
    z-index: 998;
}
.carouselCard img:before,.carouselCard video:before{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.53), rgba(0, 0, 0, 0.42), rgba(0, 0, 0, 0.18));
}
.carouselBackArrow,.carouselNextArrow {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    cursor: pointer;
    opacity: 0;
    transition: var(--transition1);
    z-index: 999;
    font-size: 1.5rem;
}
.carouselWrapper:hover>.carouselBackArrow,
.carouselWrapper:hover>.carouselNextArrow,
.carouselBackArrow.showArrows,
.carouselNextArrow.showArrows{
    opacity: 1;
}

.carouselBackArrow{
    left: 0.5rem;

}

.carouselBackArrow.clicked {
    transform: scale(1.2) translate(0,-40%);
    color: var(--color-accent-green1);
}

.carouselNextArrow{
    right: 0.5rem;
}

.carouselNextArrow.clicked {
    transform: scale(1.2) translate(0,-40%);
    color: var(--color-accent-green1);
}
.carouselDots{
    position: absolute;
    bottom: 0.5rem;
    left: 50%;
    transform: translate(-50%,0);
    z-index: 998;
}
.carouselDotCover{
    display: inline-block;
    cursor: pointer;
}
.carouselDot{
    height: 0.6rem;
    width: 0.6rem;
    background-color: white;
    border-radius: 0.3rem;

    margin-left: 0.2rem;
    margin-right: 0.2rem;
    transition: var(--transition1);
}
.carouselDot:hover {
    transform: translate(0,-50%);
}
.carouselDotActive{
    background-color: var(--color-accent-green1);
    transform: translate(0,-50%);
}
.mediaViewer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.mediaViewer img {
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
    transition: var(--transition1);
}

.mediaViewer video {
    max-width: 90%;
    max-height: 90%;
    transition: var(--transition1);
}

.closeBtn {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    font-size: 2rem;
    color: white;
    cursor: pointer;
    background: none;
}

.fullscreenBackArrow,.fullscreenNextArrow {
    position: absolute;
    top: 50%;
    font-size: 3rem;
    color: white;
    cursor: pointer;
    background: none;
    transition: var(--transition1);
}
.fullscreenBackArrow{
    left: 1.5rem;
}
.fullscreenNextArrow{
    right: 1.5rem;
}
.fullscreenBackArrow.clicked ,.fullscreenNextArrow.clicked {
    transform: scale(1.2) ;

}