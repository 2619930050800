.portfolio_container{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 2.5rem;
    color:white
}

.portfolio_item{
    background: var(--color-gray-op);
    overflow: hidden;
    border-radius: 2rem;
    transition: var(--transition1);
    text-align: left;
}
.portfolio_item-overlay{
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    padding-bottom: 1rem;
    backdrop-filter: blur(5px);
}


.portfolio_item:hover{
    background: rgba(37, 54, 22, 0.49);
}

.portfolio_item .portfolio_item-image{
    height: 15rem;
    overflow: hidden;
    border-radius: 2rem 2rem 0 0;
    position: relative;
}
.portfolio_item .portfolio_item-image img{
    object-fit: cover;
    height: 15rem;
    width: 100%;
    transition: var(--transition1);
}

.portfolio_item .portfolio_item-image div:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 2rem 2rem 0 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.43));
    z-index: 98;

}

.portfolio_item:hover .portfolio_item-image img{
    border-radius: 2rem 2rem 0 0;
    transform: scale(120%);
}

.blogArticleTitle,.portfolio_item-cta{
    transition: var(--transition1);
}
.portfolio_item:hover .blogArticleTitle{
    color: var(--color-accent-green2);
}

.portfolio_item:hover .portfolio_item-cta a{
    background: var(--color-accent-green1);
    color: white;
}

.portfolio_item h4{
    margin: 0.8rem 0 0rem;
    text-align: left;
}

.portfolio_item h3,.portfolio_item h5{
    text-align: left;
}

.portfolio_item-cta{
    display: flex;
    gap: 1rem;
    margin-top: 0.75rem;

}


@media screen and (max-width:1024px){
    .portfolio_container{
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem
    }
}

@media screen and (max-width:600px){
    .portfolio_container{
        grid-template-columns: 1fr;
        gap: 1rem
    }
}